body, html, canvas {
  margin: 0;
  padding: 0;
}

body, html {
  width: 100%;
  height: 100%;
  background: #0f172a;
}

canvas {
  display: block;
}

@media only screen and (min-width: 600px) {
  #show-mobile {
    display: none;
  }
}

@media only screen and (max-width: 600px) {
  canvas {
    display: none;
  }

  #show-mobile {
    height: 100%;
    color: #f1f1f1;
    place-items: center;
    padding: 0 50px;
    display: flex;
  }

  #show-mobile a {
    color: #fff;
    font-weight: bold;
  }
}

/*# sourceMappingURL=index.d8971234.css.map */
